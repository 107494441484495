@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F7F7;
  -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-image {
  height: auto;
  width: 20rem;
  top: 1rem;
  right: 5rem;
  position: relative;
}

  @media screen and (max-width:1000px) {
    .hero-image {
      top: 6rem;
      width: 15rem;
    }

    /* .text-left {
      margin-left: 10px;
      margin-right: 10px;
    } */
  }

  @media screen and (max-width:700px) {
    .hero-image {
      top: 4rem;
      width: 10rem;
    }

    /* .text-left {
      margin-left: 0px;
      margin-right: 5px;
    } */
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  @media screen and (max-width:1000px) {
    h1 {
      font-size: 3rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.75rem;
    }
  }

  @media screen and (max-width:700px) {
    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.75rem;
    }
  
    h3 {
      font-size: 1.5rem;
    }
  
    h4 {
      font-size: 1.25rem;
    }
  
    h5 {
      font-size: 1rem;
    }
  }


  .circular-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px; 
    height: 150px;
    border-radius: 50%; 
    overflow: hidden; 
    background-color: #f0f0f0; 
  }
  
  .circular-image {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
  }

  .box-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .box {
    width: calc(33.33% - 20px);
    padding: 28px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* Add a transition for smooth expansion */
    transition: transform 0.3s ease-in-out;
  }

  .box:hover {
    /* Scale the box slightly on hover */
    transform: scale(1.05);
  }
  
  .box-title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .box-text {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .box-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 24px 16px 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Media query for mobile view */
@media (max-width: 767px) {
  .box {
    width: 100%;
    margin: 0 auto; 
    text-align: center; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
    margin-bottom: 20px;
    position: static;
  }

  .box-title {
    font-size: 16px; 
    margin-bottom: 0;
  }

  .box-button {
    position: static; /* Remove absolute positioning */
    margin-top: 6px; /* Add margin to top for better spacing */
  }
}


  .icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .icon {
    font-size: 24px;
    margin: 8px;
  }


  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .button:hover {
    background-color: #D4D4D4;
  }
  
  